<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Panel header="Gerenciamento de Banners" style="height: 100%">
        <Toolbar class="p-mb-4">
          <template #start>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="mr-2"
              @click="showCreate()"
            />
          </template>
          <template #end>
            <div
              class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>
        </Toolbar>
        <DataTable
          ref="dt"
          dataKey="id"
          class="p-datatable-sm"
          :value="banners"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} banners."
          :loading="loading"
        >
          <template #empty>
            <div class="text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column header="Imagem/link" field="image_url">
            <template #body="slotProps">
              <a :href="slotProps.data.linkUrl" target="_blank">
                <img
                  :src="$Storage.previewImg(slotProps.data.identifierFile)"
                  class="product-image"
                />
              </a>
            </template>
          </Column>
          <Column header="Descrição" field="description" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.description }}
            </template>
          </Column>
          <Column header="Data Criação" field="dtCreated" :sortable="true">
            <template #body="slotProps">
              {{ this.$DateTime.formatarDate(slotProps.data.dtCreated) }}
            </template>
          </Column>
          <Column header="Data Atualização" field="dtUpdated" :sortable="true">
            <template #body="slotProps">
              {{ this.$DateTime.formatarDate(slotProps.data.dtUpdated) }}
            </template>
          </Column>
          <Column field="status" header="Status">
            <template #body="slotProps">
              <span
                v-if="slotProps.data.status === true"
                :class="'user-badge status-' + slotProps.data.status"
                @click="changeStatus(slotProps.data)"
                v-tooltip.top="'Clique para INATIVAR'"
                style="cursor: pointer"
                >Ativo</span
              >
              <span
                v-if="slotProps.data.status === false"
                :class="'user-badge status-' + slotProps.data.status"
                @click="changeStatus(slotProps.data)"
                v-tooltip.top="'CLIQUE ATIVAR'"
                style="cursor: pointer"
                >Inativo</span
              >
            </template>
          </Column>
          <Column header="Ações">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="showUpdate(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EDITAR'"
              />
              <Button
                icon="pi pi-camera"
                class="p-button-rounded mr-2"
                @click="showUpload(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA REALIZAR UPLOAD'"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="showRemove(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EXCLUIR'"
              />
            </template>
          </Column>
        </DataTable>
      </Panel>
    </div>
  </div>
  <ConfirmDialog></ConfirmDialog>

  <!--MODAL CADASTRAR-->
  <dialog-form :bannerSelected="banner" @findAll="findAll" />
  <!--FIM MODAL CADASTRAR-->

  <!--MODAL UPLOAD-->
  <dialog-upload
    :objectSelected="banner"
    :objectService="bannerService"
    :showImg="true"
    :keyFolder="'F-28121330f-64ca-435a-ad65-ef6a3bd34672'"
    :stateDialog="$store.state.views.banner"
    @findAll="findAll"
  />
  <!--FIM MODAL UPLOAD-->
</template>

<script>
import { FilterMatchMode } from "primevue/api";

//models
import BannerView from "../../models/banner_view";

//Services
import BannerService from "../../service/banner/banner_service";

//components
import DialogForm from "./components/dialog-form.vue";
import DialogUpload from "../../components/upload/dialog-upload.vue";

export default {
  components: {
    DialogForm,
    DialogUpload,
  },
  data() {
    return {
      loading: false,
      banners: [],
      banner: new BannerView(),
      permissoes: [],
      filters: {},
      filtersPermissions: {},
      bannerService: new BannerService(),
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.findAll();
  },
  methods: {
    showCreate() {
      this.banner = new BannerView();
      this.$store.state.views.banner.dialogForm = true;
    },
    showUpdate(banner) {
      this.banner = { ...banner };
      this.$store.state.views.banner.dialogForm = true;
    },
    showUpload(banner) {
      this.banner = banner;
      this.$store.state.views.banner.dialogUpload = true;
    },
    showRemove(banner) {
      this.banner = banner;
      this.$confirm.require({
        message: "Deseja Excluir esse registro?",
        header: "Deseja deletar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.loading = true;

          if (!this.banner.imageUrl) {
            this.deleteData();
          } else {
            this.$Firebase
              .onDeleteByURL(this.banner.imageUrl)
              .then(() => {
                this.deleteData();
              })
              .catch((err) => {
                this.loading = false;
                this.$toast.add({
                  severity: "error",
                  summary: "Alerta de Erro.",
                  detail: err,
                  life: 6000,
                });
              });
          }
        },
      });
    },
    changeStatus(banner) {
      this.bannerService
        .updateStatus(banner)
        .then((data) => {
          this.$msgSuccess(data);
          this.findAll();
        })
        .catch((error) => {
          this.$msgErro(error);
          //console.error(error);
        });
    },
    deleteData() {
      this.bannerService
        .delete(this.banner.id)
        .then((data) => {
          this.$msgSuccess(data);
          this.findAll();
        })
        .catch((error) => {
          this.$msgErro(error);
          this.loading = false;
          //console.error(error);
        });
    },
    findAll() {
      this.loading = true;
      this.bannerService.findAll().then((data) => {
        this.banners = data;
        this.loading = false;
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../App.scss");
.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
</style>
