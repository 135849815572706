<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '720px' }"
    header="Formulário de Banner"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="field">
      <label for="description">Descrição</label>
      <InputText
        id="description"
        v-model="v$.banner.description.$model"
        maxlength="255"
        placeholder="Digite a descrição do banner"
        :class="{ 'p-invalid': submitted && v$.banner.description.$invalid }"
      />
      <small class="p-error" v-if="submitted && v$.banner.description.$invalid"
        >Campo obrigatório.</small
      >
    </div>

    <div class="field">
      <label for="linkUrl">Link de Acesso</label>
      <InputText
        id="linkUrl"
        v-model="banner.linkUrl"
        maxlength="255"
        placeholder="Digite o link de acesso (caso necessário)"
      />
    </div>
    <!--
    <div v-if="banner.imageUrl" class="field mt-3">
      <label for="image">Imagem/Link</label>
      <a :href="banner.linkUrl" target="_blank">
        <img id="image" :src="banner.imageUrl" class="product-image" />
      </a>
    </div>
    -->

    <!--
    <div class="field">
      <FileUpload
        name="demo[]"
        accept="image/*"
        :maxFileSize="2000000"
        @select="onSelect"
        :showUploadButton="false"
        chooseLabel="Escolha uma imagem"
      >
        <template #content>
          <ul v-if="uploadedFiles && uploadedFiles[0]">
            <li v-for="file of uploadedFiles[0]" :key="file">
              {{ file.name }} - {{ file.size }} bytes
            </li>
          </ul>
        </template>
        <template #empty>
          <p>Nenhum arquivo selecionado</p>
        </template>
      </FileUpload>
      <ProgressBar mode="indeterminate" v-if="initProgress" />
    </div>
    -->

    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.banner.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>

<script>
//Models
import BannerView from "../../../models/banner_view";

//Services
import BannerService from "../../../service/banner/banner_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["bannerSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      banner: new BannerView(),
      submitted: false,
      bannerService: new BannerService(),
      fileSelected: null,
      initProgress: false,
    };
  },
  validations() {
    return {
      banner: new BannerView().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.banner.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.banner.dialogForm = value;
      },
    },
  },
  methods: {
    /*
    onSelect(obj) {
      this.$Firebase
        .onSelect(obj)
        .then((file) => {
          this.fileSelected = file;
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Erro!",
            detail: err,
            life: 3000,
          });
        });
    },
    */
    send(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      } else {
        if (this.banner.id) {
          this.update();
        } else {
          this.create();
        }
      }
    },
    create() {
      this.bannerService
        .create(this.banner)
        .then((response) => {
          this.$msgSuccess(response);
          this.$emit("findAll");
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.bannerService
        .update(this.banner)
        .then((response) => {
          this.$msgSuccess(response);
          this.$emit("findAll");
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
          this.initProgress = false;
        });
    },
    /*
    conditionalForUpload(isFormValid) {
      this.submitted = true;
      if (this.banner.imageUrl && !this.fileSelected) {
        this.send();
      } else if (!this.banner.imageUrl && !this.fileSelected && isFormValid) {
        this.$toast.add({
          severity: "error",
          summary: "Erro!",
          detail: "O arquivo do banner é obrigatório.",
          life: 3000,
        });
        return;
      } else {
        this.onUpload();
      }
    },
    */
    hideDialog() {
      this.banner = new BannerView();
      this.submitted = false;
      this.initProgress = false;
      this.visibleDialog = false;
    },
    getData() {
      this.banner = this.bannerSelected;
    },
  },
};
</script>

<style scoped>
.product-image {
  transform: scale(
    0.8
  ); /* Reduz a escala da imagem para 80% do seu tamanho original */
  max-width: 100%;
  height: auto;
}
</style>
