import { required } from "@vuelidate/validators";
export default class BannerView {

    constructor() {
        this.id = null;
        this.identifierFile = null;
        this.linkUrl = null;
        this.description = null;
        this.dtCreated = null;
        this.dtUpdated = new Date();
        this.status = false;
    }
    validations() {
        return {
            description: {
                required,
            },
        };
    }


}